import { Injectable, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpService } from "../http.service";
import { GenelService } from '../genel/genel/genel.service';
import { environmentqa } from 'src/environments/environment.qa';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {
  baseUrl = environmentqa.apiUrl
  public userData: any;
  public user: firebase.User;
  public showLoader: boolean = false;
  ip: any;
  loginLogData;
  constructor(public afs: AngularFirestore,
    // public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private httpClient: HttpClient,
    private http: HttpService,
    private cookieService: CookieService, private genel: GenelService) {

    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     cookieService.set('user', JSON.stringify(this.userData));
    //     sessionStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(sessionStorage.getItem('user'));
    //   } else {
    //     sessionStorage.setItem('user', null);
    //     JSON.parse(sessionStorage.getItem('user'));
    //   }
    // });

  }

  stopService: Subscription
  ngOnInit(): void {
    // this.stopService = this.httpClient.get("https://api.ipify.org/?format=json").subscribe(res => {
    //   this.ip = ""

    // })
  }
  ngOnDestroy(): void {
    this.stopService.unsubscribe()
  }
  private setEmit = new BehaviorSubject<any>(undefined);
  public getEmit: Observable<any> = this.setEmit

  setEmitServis(value) {
    this.setEmit.next(value);
  }
  hataliGirisSayisi = 0
  async SignInGet(userName: string, password: string, key, captchaResult: string) {

    return this.http.httpLoginPost("/Auth/Login", {
      userName: userName,
      password: password,
      captchaDeger: captchaResult,
      captchaKey: key

    }, false, true).then(
      response => {

        response?.basariliMi == false ? this.genel.mesajKontrol(response?.basariliMi, response.mesaj[0]) : ''
        if (response.data.kullaniciBilgileri.ilkGirisMi == false && response.data.kullaniciBilgileri.sifreGunKontrolu == false) {
          this.SetUserData(response)
          this.genel.mesajKontrol(response?.basariliMi, response.mesaj[0])
          // this.loginLog(userName, response.basariliMi)
          this.router.navigate(['/dashboard/default'])
        }
        else if (response.data.kullaniciBilgileri.ilkGirisMi == true) {
          this.setEmitServis({ type: 1, user: response })
          this.toster.warning('Lütfen yeni şifre belirleyiniz.', 'İlk Giriş')
        }
        else if (response.data.kullaniciBilgileri.sifreGunKontrolu == true) {
          this.setEmitServis({ type: 2, user: response })
          this.toster.warning('Lütfen Yeni Şifre Belirleyiniz.', 'En son giriş 90 günü geçmiştir')
        }

      }).catch(error => {
        if (error.status == 400) {

          this.hataliGirisSayisi++
          this.cookieService.set("count", `${this.hataliGirisSayisi}`, 1, "", "", true, "Lax")
          if (this.hataliGirisSayisi == 3 || this.hataliGirisSayisi > 3) {
            this.setEmitServis({ type: 0, user: null })
          }

        }
        // sessionStorage.setItem('user', null);
        // JSON.parse(sessionStorage.getItem('user'));
        //this.loginLog(userName, false)

        this.toster.error(error.error.mesaj[0], 'Hata')
      })



  }


  Logout() {
    //this.loginLogUpdate()
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("refreshToken")
    localStorage.removeItem("hastaTuru")
    localStorage.removeItem("hizmetTarihi")
    localStorage.removeItem("provesionId")
    localStorage.removeItem("islemTipId")
    localStorage.removeItem("bransId")
    localStorage.removeItem('sysUserLogData')
    location.reload()
  }


  async SetUserData(user) {
    sessionStorage.removeItem("user")
    this.showLoader = true
    this.userData = user

    sessionStorage.setItem("user", JSON.stringify(user))
    sessionStorage.setItem('token', JSON.stringify(JSON.parse(JSON.stringify(user)).data.token))
    sessionStorage.setItem('refreshToken', JSON.stringify(JSON.parse(JSON.stringify(user)).data.refreshToken))
    new HttpHeaders().set('Authorization', 'Bearer ' + JSON.parse(JSON.stringify(user)).data.refreshToken)

  }
  async loginLog(userName: string, status: boolean) {

    let loginLogData = {
      "kullaniciAdi": userName,
      "activeDirectoryUser": "-",
      "basariliMi": status,
      "ip": "127.0.0.1",
      "aciklama": "Ülke-Dil : " + `${navigator.language}` + " , " + "Browser : " + `${navigator.userAgent}`
    }

    // this.http.httpPost('SistemGirisLog', loginLogData).then(response => {

    //   localStorage.setItem("sysUserLogData", response.data)

    // })
  }

  async loginLogUpdate() {
    // let data = {}
    // let sysData = localStorage.getItem("sysUserLogData")
    // this.http.httpPut("SistemGirisLog?id=" + `${sysData}`, data).then(response => {

    // })
  }


  // ForgotPassword(passwordResetEmail) {
  //   return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
  //     .then(() => {
  //       window.alert('Password reset email sent, check your inbox.');
  //     }).catch((error) => {
  //       window.alert(error);
  //     });
  // }

  // // Authentication for Login
  // AuthLogin(provider) {
  //   return this.afAuth.auth.signInWithPopup(provider)
  //     .then((result) => {
  //       this.ngZone.run(() => {
  //         this.router.navigate(['/dashboard/default']);
  //       });
  //       this.SetUserData(result.user);
  //     }).catch((error) => {
  //       window.alert(error);
  //     });
  // }

  // Set user
  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     email: user.email,
  //     displayName: user.displayName,
  //     uid: user.uid,
  //     photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
  //     emailVerified: user.emailVerified
  //   };
  //   userRef.delete().then(function () {})
  //         .catch(function (error) {});
  //   return userRef.set(userData, {
  //     merge: true
  //   });
  // }

  // Sign out
  // SignOut() {
  //   this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //     return false;
  //   };
  //   return this.afAuth.auth.signOut().then(() => {
  //     this.showLoader = false;
  //     sessionStorage.clear();
  //     this.cookieService.deleteAll('user', '/auth/login');
  //     this.router.navigate(['/auth/login']);
  //   });
  // }

  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }

}