import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(public authService: AuthService,
    public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // var userData:any= sessionStorage.getItem("user");

    let user = JSON.parse(sessionStorage.getItem('user'));
    if (!user || user.data.token === null) {
      this.router.navigate(['/auth/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true

      }
    }

    // .map((item: any) => item.menuKod)
    let menuKod = []
    let rigths = ((user.data.kullaniciBilgileri.menu.ustMenuListesi.filter((item: any) => item.menuKod == 7469)[0] || {})
      .baslikMenuListesi || []).map((item: any) => [item, ...item.altMenuListesi]).flat()
      .filter((item: any) => item.gorsunMu)

    for (let i = 0; i < rigths.length; i++) {
      const element = rigths[i];
      menuKod.push(element.menuKod)

      if (element.icMenuListesi != null) {
        for (let index = 0; index < element.icMenuListesi.length; index++) {
          const element2 = element.icMenuListesi[index];
          menuKod.unshift(element2.menuKod)
        }
      }
    }

    // console.log(rigths.filter((item: any) => item.icMenuListesi != null))

    if (typeof next.data.menuId !== "undefined" && !menuKod.includes(next.data.menuId)) {

      this.router.navigateByUrl("/error-page/error-404");
      return false;
    }
    return true;
  }


  //   if (!user || user.data.token === null) {
  //     this.router.navigate(['/auth/login']);
  //     return true
  //   }
  //   else if (user) {
  //     if (!Object.keys(user).length) {
  //      this.router.navigate(['/auth/login']);
  //       return true

  //     }
  //   }
  //   return true
  // }

}
