<div class="login-main">
  <form class="theme-form">
    <div class="theme-form__top">
      <h2>Halk Bankası Emekli Sandığı Provizyon Sistemi</h2>

      <h4 class="m-0">Yeni Şifre al</h4>
    </div>
    <hr class="card-hr m-3" />
    <div class="theme-form__content">
      <!-- Şifreyi unuttum -->
      <div *ngIf="loginType == 1">
        <div>
          <div class="form-group mb-3">
            <label class="label">Kullanıcı Adı</label>
            <input
              type="text"
              [(ngModel)]="kullaniciAdi"
              placeholder="Kullanıcı Adını Giriniz."
              class="form-control"
            />
          </div>
          <div class="form-group mb-3">
            <label class="label">Telefon No son 4 hanesi</label>
            <input
              type="text"
              [(ngModel)]="telSon4Hane"
              placeholder="Telefon Numaranızın son 4 hanesi"
              class="form-control"
            />
          </div>

          <!-- <div class="form-group mb-3">
            <re-captcha
              hidden
              (resolved)="resolved($event)"
              siteKey="6LfGWzUjAAAAAFcjR5ps3wkXOftBnfft_2Jb2RF8"
            ></re-captcha>
          </div> -->
          <!-- <div class="d-flex">
            <app-capthca [captchaText]="captchaText"></app-capthca>
          </div>

          <div>
            <input
              type="text"
              placeholder="Güvenlik Kodunu Giriniz."
              [(ngModel)]="captchaText"
            />
          </div> -->
        </div>

        <div class="form-group mt-2">
          <button
            [disabled]="
              telSon4Hane == null ||
              telSon4Hane == '' ||
              kullaniciAdi == null ||
              kullaniciAdi == ''
            "
            (click)="sifremiUnuttum()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Gönder</span>
          </button>
        </div>
      </div>
      <!-- Şifreyi unuttum  end-->

      <!-- Sms -->

      <div *ngIf="loginType == 2">
        <div class="form-group mb-3">
          <label class="label">Sms Kodunu Giriniz</label>
          <input
            type="text"
            [(ngModel)]="smsKodu"
            placeholder="Sms Kodu"
            class="form-control"
          />
        </div>
        <div class="form-group m-0">
          <button
            (click)="smsKoduDogrulu()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Gönder</span>
          </button>
        </div>
      </div>
      <!-- Sms  end-->

      <!-- İlk giris kontrolü -->

      <div *ngIf="loginType == 3 || loginType == 4 || loginType == 5">
        <div class="form-group mb-3">
          <label class="label">Yeni Şifre</label>
          <input
            [(ngModel)]="sifre1"
            type="password"
            placeholder="Yeni Şifre"
            class="form-control"
          />
        </div>
        <div class="form-group mb-3">
          <label class="label">Şifre Tekrar</label>
          <input
            [(ngModel)]="sifre2"
            type="password"
            placeholder="Şifre Tekrarı"
            class="form-control"
          />
        </div>
        <div class="form-group m-0" *ngIf="loginType == 3">
          <button
            (click)="ilkGirisKontrol()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Gönder</span>
          </button>
        </div>

        <div class="form-group m-0" *ngIf="loginType == 4">
          <button
            (click)="gunKontrol()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Şifremi Güncelle</span>
          </button>
        </div>
        <div class="form-group m-0" *ngIf="loginType == 5">
          <button
            (click)="smsKoduIleSifreGuncelle()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Şiremi Güncelle</span>
          </button>
        </div>
      </div>

      <!-- İlk giris kontrolü  end-->
    </div>
  </form>
</div>
