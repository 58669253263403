import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime, groupBy } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { ControlPosition } from '@agm/core';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	gorunsunMu?: boolean;
	menuKod?: number;
	ustMenuID?: number;
	activeIndex?: number;
	children?: Menu[];

}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit, OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;
	getSession
	getMenu: any = []
	constructor(private router: Router, private http: HttpService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}

		this.getSession = JSON.parse(sessionStorage.getItem("user"));
		this.getMenu = this.getSession.data.kullaniciBilgileri.menu

		let ustMenu = []
		let altMenu = []
		for (let index = 0; index < this.getMenu.ustMenuListesi.length; index++) {
			const element = this.getMenu.ustMenuListesi[index];
			ustMenu.push(element)
		}
		for (var i = ustMenu.length - 1; i >= 0; i--) {
			if (ustMenu[i].menuKod === 4265) {
				ustMenu.splice(i, 1);
			}
		}
		//console.log(this.getMenu.ustMenuListesi)

		for (let index = 0; index < ustMenu[0].baslikMenuListesi.length; index++) {
			const element2 = ustMenu[0].baslikMenuListesi[index];

			if (element2.link == null || element2.link == "" || element2.link == undefined) {
				this.MENUITEMS.push({
					title: `${element2.linkAd}`, icon: `${element2.aciklama}`, gorunsunMu: element2.gorunsunMu, menuKod: element2.menuKod, type: 'sub', active: false, children: []
				},)
			} else {
				this.MENUITEMS.push({ title: `${element2.linkAd}`, icon: `${element2.aciklama}`, menuKod: element2.menuKod, type: `link`, active: false, path: `${element2.link}` },)
			}
			for (let index = 0; index < element2.altMenuListesi.length; index++) {
				const element3 = element2.altMenuListesi[index];
				altMenu.push({ title: `${element3.linkAd}`, icon: 'far fa-plus', type: 'link', ustMenuID: element3.ustMenuID, active: false, path: `${element3.link}` },)
			}
		}

		for (let index = 0; index < altMenu.length; index++) {
			const element = altMenu[index];
			for (let index = 0; index < this.MENUITEMS.length; index++) {
				const element2 = this.MENUITEMS[index];
				if (element2.menuKod == element.ustMenuID) {
					this.MENUITEMS[index].children.push(element)
				}
			}
		}


	}
	ngOnInit(): void {

	}

	menuData
	GetMenu() {
		for (let index = 0; index < this.getMenu.length; index++) {
			const element = this.getMenu.baslikMenuListesi[0].altMenuListesi[index];
		}
	}
	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [

		//  { title: 'Ana Sayfa', icon: 'far fa-home', type: 'link', active: false, path: '/dashboard/default' },
		// {
		// 	title: 'Provizyon', icon: 'far fa-plus', type: 'sub', active: false, children: [
		// 		{ title: 'Yeni Provizyon', icon: 'far fa-plus', type: 'link', active: false, path: '/Fatura-Provizyon/mustehak-sorgula' },
		// 		{ title: 'Provizyon Listesi', icon: 'far fa-plus', type: 'link', active: false, path: '/Fatura-Provizyon/provizyon-detay' },
		// 		{ title: 'Yeni Optik Provizyon', icon: 'far fa-plus', type: 'link', active: false, path: '/optik-provizyon/optik-mustehak-sorgula' },
		// 		{ title: 'Provizyon Listesi', icon: 'far fa-plus', type: 'link', active: false, path: '/optik-provizyon/optik-listesi' }
		// 	]
		// },

		// {
		// 	title: 'Seanslı İşlem Talep', icon: 'far fa-calendar', type: 'sub', active: false, children: [
		// 		{ title: 'Hak Talep', icon: 'far fa-plus', type: 'link', active: false, path: '/seansli-islem-talep/mustehaklik-sorgula' },
		// 		{ title: 'Hak Talep Listesi', icon: 'far fa-plus', type: 'link', active: false, path: '/seansli-islem-talep/hak-talep-listesi' }
		// 	]
		// },
		// { title: 'Provizyon Kontrol', icon: 'far fa-file-text', type: 'link', active: false, path: 'provizyon-kontrol/provizyon-kontrol-listesi' },

		// {
		// 	title: 'Provizyon Paket', icon: 'far fa-archive', type: 'sub', active: false, children: [
		// 		{ title: 'Yeni Provizyon Paketi', icon: 'far fa-plus', type: 'link', active: false, path: 'provizyon-paket/provizyon-paket-olustur' },
		// 		{ title: 'Provizyon Paket Listesi', icon: 'far fa-plus', type: 'link', active: false, path: 'provizyon-paket/provizyon-paket-listesi' }
		// 	]
		// },

	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: '/error-page/error-400', title: 'Error Page 400', type: 'link' },
				{ path: '/error-page/error-401', title: 'Error Page 401', type: 'link' },
				{ path: '/error-page/error-403', title: 'Error Page 403', type: 'link' },
				{ path: '/error-page/error-404', title: 'Error Page 404', type: 'link' },
				{ path: '/error-page/error-500', title: 'Error Page 500', type: 'link' },
				{ path: '/error-page/error-503', title: 'Error Page 503', type: 'link' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: '/authentication/simple-login', title: 'Login Simple', type: 'link' },
				{ path: '/authentication/login-with-background-image', title: 'Login BG Image', type: 'link' },
				{ path: '/authentication/login-with-background-video', title: 'Login BG Video', type: 'link' },
				{ path: '/authentication/simple-register', title: 'Simple Register', type: 'link' },
				{ path: '/authentication/register-with-background-image', title: 'Register BG Image', type: 'link' },
				{ path: '/authentication/register-with-background-video', title: 'Register BG Video', type: 'link' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: '/search-pages', title: 'Search Pages', type: 'link' },
				{ path: '/authentication/unlock-user', title: 'Unlock User', type: 'link' },
				{ path: '/authentication/forgot-password', title: 'Forgot Password', type: 'link' },
				{ path: '/authentication/reset-password', title: 'Reset Password', type: 'link' },
				{ path: '/maintenance', title: 'Maintenance', type: 'link' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: '/coming-soon/simple', title: 'Coming Simple', type: 'link' },
				{ path: '/coming-soon/simple-with-bg-img', title: 'Coming BG Image', type: 'link' },
				{ path: '/coming-soon/simple-with-bg-vid', title: 'Coming BG Video', type: 'link' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: '/file-manager', title: 'File Manager', icon: 'git-pull-request', type: 'link'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/user/team-details', title: 'All Users', icon: 'users', type: 'link' },
				{ path: '/user/profile', title: 'User Profile', icon: 'users', type: 'link' },
				{ path: '/user/edit-profile', title: 'Edit Profile', icon: 'users', type: 'link' },
			]
		},
		{ path: '/bookmarks', title: 'Bookmarks', icon: 'heart', type: 'link' },
		{ path: '/calender', title: 'Calender', icon: 'calendar', type: 'link' },
		{ path: '/social-app', title: 'Social App', icon: 'zap', type: 'link' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
