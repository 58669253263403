import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print5',
  templateUrl: './print5.component.html',
  styleUrls: ['./print5.component.scss']
})
export class Print5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
