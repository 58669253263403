import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print4',
  templateUrl: './print4.component.html',
  styleUrls: ['./print4.component.scss']
})
export class Print4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
