export const environment = {
  env: 'Dev',
  // baseUrl: "http://10.57.110.13/api",//pre prod
  // baseUrl: "http://10.57.146.54/api",//halk test
  baseUrl: "https://shyortakapi.monassist.com/api", //mon test
  production: true,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"

  }
};

