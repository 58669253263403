import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timer-control',
  templateUrl: './timer-control.component.html',
  styleUrls: ['./timer-control.component.scss']
})
export class TimerControlComponent implements OnInit {
  timer: any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.resetTimer();
  }


  resetTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.router.navigate(['/auth/login']);

    }, 60000); // 10 dakika 600000
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:keypress', ['$event'])
  resetTimerOnUserInteraction(event: MouseEvent | KeyboardEvent) {
    this.resetTimer();
    console.log("tetiklendi.")

  }
}
