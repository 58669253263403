import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http"; 
import { environmentqa } from "../../../environments/environment.qa";
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import {Token} from "src/app/shared/model/token";
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class HttpService implements HttpInterceptor{
 baseURL=environmentqa.apiUrl;
  //baseURL='https://shyortakapi.monassist.com/api'
 //baseURL='https://localhost:7176/api'


  //baseURL=window.sessionStorage.getItem('apiUrl')
  
  user;
constructor(public toster: ToastrService,private httpClient:HttpClient,public router: Router, private http: HttpClient,private spinner:NgxSpinnerService) {
  this.user = JSON.parse(sessionStorage.getItem("user"));
  let subject = new BehaviorSubject(this.user);
  
// fetch('/assets/config/config.json').then(res => res.json())
//   .then(jsonData => {
   
//     this.baseURL = jsonData.apiUrl;
//     window.sessionStorage.setItem('apiUrl',jsonData.apiUrl)
//   })

 }

token:Token
kullaniciData={
  userName:String,
  password:String
}
 async httpLoginPost(endPoint, data, values?: boolean, auth?: boolean): Promise<any> {
  this.kullaniciData.userName=data.userName
  if (auth) {
    return this.http
      .post(this.baseURL +  endPoint, data,)
      .toPromise()
      .catch(err => {
       
        throw err;
       
     
      })
  } else {
    this.user = JSON.parse(sessionStorage.getItem("user"));
   
    return this.http
      .post(this.baseURL +  endPoint, data, {
        headers: { Authorization: `Bearer ${this.user.data.token}` },
      })
      .toPromise().catch(err=>{
      
        if (err.status==401) {
          return this.http
          .post(this.baseURL +  endPoint, data, {
            headers: { Authorization: `Bearer ${this.user.data.token}`},
          })
        }
      
      });
  }
 }

 get(endPoint:string): Observable<any>{
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +user.data.token
  })


 return this.http.get(`${this.baseURL}/${endPoint}`, {headers:reqHeader})
 }

 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  this.spinner.show();

  return next.handle(req)
       .pipe(tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                  this.spinner.hide();
              }
          }, (error) => {
              this.spinner.hide();
               this.toster.error('sad','asdas')
          }));
}

refreshToken(){
  let user = JSON.parse(sessionStorage.getItem("user"))
  let refreshToken = JSON.parse(sessionStorage.getItem("refreshToken"))


  // 'Authorization': 'Bearer ' + token
  this.httpClient.get(`${this.baseURL}/Auth/RefreshToken?kullaniciAdi=${user.data.kullaniciBilgileri.kullaniciAdi}&refreshToken=${refreshToken}`, { headers: { 'accept': '*/*' } }).toPromise().then(res => {
 
    sessionStorage.setItem('token', JSON.stringify(res['data'].token))

    //  this.reqHeader.set('Authorization','Bearer ' +res['data'].token)
  },err=>{
    if (err.status===400) {
      this.router.navigateByUrl('/auth/login')
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('tc')
      sessionStorage.removeItem('refreshToken')
    }else if(err.status===401){
      this.router.navigateByUrl('/auth/login')
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('tc')
      sessionStorage.removeItem('refreshToken')
    }
  })
}
sayac=0
async httpGet(endPoint:string): Promise<any> {
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
      return  this.http
      .get(`${this.baseURL}/${endPoint}`,{headers:reqHeader})
      .toPromise()
      .catch(err => {
        if(err.status === 511){
          sessionStorage.removeItem("user");
          this.router.navigate(["/Auth/Login"])
        }
        if (err.status===401) {
          this.refreshToken()
        }
      })
 }

 async httpPost(endPoint, data): Promise<any> {
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
     return  this.http
     .post(`${this.baseURL}/${endPoint}`,data, {headers:reqHeader}
   ).toPromise()
       .catch(err => {
        if (err.status===401) {
         this.refreshToken()
        }
       })
 }

 async httpPostFile(endPoint, data:any): Promise<any> {
  let token=JSON.parse(sessionStorage.getItem("token"))
      var reqHeader = new HttpHeaders({
       // 'ApiKey': 'Pnc1a60tc7Qf4WzoD9El8Gw7szSbN6CBqrP8Tqj3vRo=.KcXxS6dUi9yd28tixsb7w47KZ8F4F2zR',
        // 'Content-Type':"multipart/form-data; boundary=----WebKitFormBoundaryq2lzt9nK5blfZIf0",
        'Authorization': 'Bearer ' + token
      
       
      })
     return  this.http
     .post(`${this.baseURL}/${endPoint}`,data, {headers:reqHeader,reportProgress: true}
   ).toPromise()
       .catch(err => {
        if (err.status===401) {
          let refreshToken=JSON.parse(sessionStorage.getItem("user")).data.refreshToken
          var reqHeader = new HttpHeaders({
            //'ApiKey': 'Pnc1a60tc7Qf4WzoD9El8Gw7szSbN6CBqrP8Tqj3vRo=.KcXxS6dUi9yd28tixsb7w47KZ8F4F2zR',
            'Authorization': 'Bearer ' +refreshToken
          })
      
          return  this.http
          .get(`${this.baseURL}/${endPoint}`, {headers:reqHeader,reportProgress: true})
          .toPromise().catch(err=>{
            if (err.status===401) {
              let refreshToken=JSON.parse(sessionStorage.getItem("user")).data.refreshToken
              var reqHeader = new HttpHeaders({
               // 'ApiKey': 'Pnc1a60tc7Qf4WzoD9El8Gw7szSbN6CBqrP8Tqj3vRo=.KcXxS6dUi9yd28tixsb7w47KZ8F4F2zR',
                'Authorization': 'Bearer ' +refreshToken
              })
          
              return  this.http
              .get(`${this.baseURL}/${endPoint}`, {headers:reqHeader,observe: 'response',responseType: 'blob'})
              .toPromise().catch(err2=>{
                if (err2.status===401) {
                  this.router.navigateByUrl('/auth/login')
                }
              })
            }
          })
        }
       })
 }

 async httpGetFile(endPoint:string): Promise<any> {
  let token=JSON.parse(sessionStorage.getItem("token"))
  
  var reqHeader = new HttpHeaders({
    'accept':'*/*',
  
    'Authorization': 'Bearer ' +token
  })
  
     
      return  this.http
      .get(`${this.baseURL}/${endPoint}`, {headers:reqHeader,observe: 'response',responseType: 'blob'})
      .toPromise()
      .catch(err => {
   
   
        if(err.status === 511){
          sessionStorage.removeItem("user");
          this.router.navigate(["/Auth/Login"])
        }
    
      })
    
 }

 async httpGetFileDownload(endPoint:string): Promise<any> {
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
    try {
     
      return  this.http
      .get(`${endPoint}`, {headers:reqHeader,observe: 'response',responseType:'blob'})
      .toPromise()
      .catch(err => {
      
        if(err.status === 511){
          sessionStorage.removeItem("user");
          this.router.navigate(["/Auth/Login"])
         
        }
        
      })
    } catch (error) {
      throw error;
    }
 }


  httpPostFile2(endPoint, data:any) {
    let user=JSON.parse(sessionStorage.getItem("user"))
    let token=JSON.parse(sessionStorage.getItem("token"))
    var reqHeader = new HttpHeaders({
      //'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    })
     return  this.http.post(`${this.baseURL}/${endPoint}`,data, {headers:reqHeader,reportProgress: true}
   )
 }

 
 post(endPoint, data){
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
 return this.http.post(`${this.baseURL}/${endPoint}`,data, {headers:reqHeader})
}

 async httpPut(endPoint, data): Promise<any> {
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
     return await this.http
     .put(`${this.baseURL}/${endPoint}`,data, {headers:reqHeader}
   ).toPromise()
       .catch(err => {
        if (err.status===401) {
          this.refreshToken()
         }
       })
 }

 async httpPutId(endPoint): Promise<any> {
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
     return await this.http
     .put(`${this.baseURL}/${endPoint}`, {headers:reqHeader}
   ).toPromise()
       .catch(err => {
        if (err.status===401) {
          this.refreshToken()
         }
       })
 }

 async httpDelete(endPoint):Promise<any>{
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
     return await this.http
     .delete(`${this.baseURL}/${endPoint}`,{headers:reqHeader}
   ).toPromise()
       .catch(err => {
        if (err.status===401) {
         
            this.refreshToken()
           
        }
       })
 }

 delete(endPoint:string): Observable<any>{
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
 return this.http.delete<any>(`${this.baseURL}/${endPoint}`, {headers:reqHeader})
 }

 put(endPoint, data){
  let user=JSON.parse(sessionStorage.getItem("user"))
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
 return this.http.put(`${this.baseURL}/${endPoint}`,data, {headers:reqHeader})
}

putId(endPoint){
  let token=JSON.parse(sessionStorage.getItem("token"))
  var reqHeader = new HttpHeaders({
    //'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
  })
 return this.http.put(`${this.baseURL}/${endPoint}`, {headers:reqHeader})
}

}



//Get(endPoint:string):Observable<any>{
  //   let headers = new HttpHeaders({
  //     //'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Headers': 'Content-Type',
  //     'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  //     'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`
  //   });
  
  //  return this.http.get(this.baseURL+'/'+endPoint,{headers});
  
  // }