import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(public toster: ToastrService,) { }


  successMessage(message: string, title = "") {
    return this.toster.success(message, title, { timeOut: 15000 })
  }

  errorMessage(message: string, title = "") {
    return this.toster.error(message, title, { timeOut: 15000 })
  }

  warningMessage(message: string, title = "") {
    return this.toster.warning(message, title, { timeOut: 15000 })

  }
  infoMessage(message: string, title = "") {
    return this.toster.info(message, title, { timeOut: 15000 })

  }
}
