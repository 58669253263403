import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaptchaEmitService {

  constructor() { }
  private setEmit = new BehaviorSubject<any>(undefined);
  public getEmit: Observable<any> = this.setEmit
  private setCodeEmit = new BehaviorSubject<any>(undefined);
  public getCodeEmit: Observable<any> = this.setCodeEmit

  setOptikEmitServis(value) {
    this.setEmit.next(value);
  }

  setEmitCodeServis(value) {
    this.setCodeEmit.next(value);
  }
}
