import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class LoginIslemleriService {

  constructor(private http: HttpService) { }

  sifremiUnuttum(kullaniciAdi, telSon4Hane) {
    return this.http.httpGet(`/Auth/SifremiUnuttum?KullaniciAdi=${kullaniciAdi}&TelSonDortHane=${telSon4Hane}`)
  }
  smsKoduDogrulu(kullaniciAdi, smsKodu) {
    return this.http.httpGet(`Auth/SmsKoduDogrula?KullaniciAdi=${kullaniciAdi}&SmsKodu=${smsKodu}`)
  }
  ilkGirisSifreGuncelle(data) {
    return this.http.httpPut('LoginIslemleri/IlkGirisSifreGuncelle', data)
  }

  gunKontrolSifreGuncelle(data) {
    return this.http.httpPut('LoginIslemleri/GunKontrolSifreGuncelle', data)
  }

  smsKoduIleSifreGuncelle(data) {
    return this.http.httpPut('LoginIslemleri/SmsKoduIleSifreGuncelle', data)
  }
}
