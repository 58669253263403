<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <i
          class="far fa-chevron-left mr-5"
          style="font-size: 24px; cursor: pointer"
          onclick="history.back();"
          *ngIf="urlState"
        ></i>
        <h3>{{ title }}</h3>
      </div>
      <div class="col-6" hidden>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<!-- <app-timer-control></app-timer-control> -->
