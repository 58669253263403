import { Component, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CaptchaEmitService } from "../../../services/emitService/captcha-emit/captcha-emit.service";
@Component({
  selector: 'app-capthca',
  templateUrl: './capthca.component.html',
  styleUrls: ['./capthca.component.scss']
})
export class CapthcaComponent implements OnInit {

  constructor(private cookieService: CookieService, private captchaEmit: CaptchaEmitService) { }

  ngOnInit(): void {
    this.emit();
  }

  emit() {
    this.captchaEmit.getEmit.subscribe(res => {
      this.createCaptcha();
    })
  }
  code;
  @Input() captchaText = "";

  createCaptcha() {

    document.getElementById('captcha').innerHTML = "";
    var charsArray =
      "012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {

      var index = Math.floor(Math.random() * charsArray.length + 1);
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 90;
    canv.height = 30;

    var ctx = canv.getContext("2d");
    // ctx.fillStyle = "blue";
    // ctx.fillRect(0, 0, canv.width, canv.height);
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 20);
    this.code = captcha.join("");
    document.getElementById("captcha").appendChild(canv);
    this.captchaEmit.setEmitCodeServis(this.code)

    // this.cookieService.set("count", JSON.stringify(count = +1))
  }



}
