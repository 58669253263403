import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vkf-dis-provizyon-olustur',
  templateUrl: './vkf-dis-provizyon-olustur.component.html',
  styleUrls: ['./vkf-dis-provizyon-olustur.component.scss']
})
export class VkfDisProvizyonOlusturComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
