<body>
  <form>
    <label>Doğrulama Kodu</label>
    <div class="d-flex">
      <div id="captcha"></div>
      <a (click)="emit()">
        <app-feather-icons
          [icon]="'refresh-cw'"
          class="txt-linkedin ml-2 res-cp"
        ></app-feather-icons
      ></a>
    </div>

    <!-- <input
      type="text"
      placeholder="Captcha"
      id="cpatchaTextBox"
      [(ngModel)]="captchaText"
    /> -->
    <!-- <button (click)="validateCaptcha()" type="submit">Submit</button> -->
  </form>
</body>
